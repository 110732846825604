//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import {
  helpers,
  required,
  numeric,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators'
import AuthOverlay from '../AuthOverlay.vue'
import PasswordInput from '~/components/UI/PasswordInput.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import validationWatcher from '~/mixins/validationWatcher'
import getErrorsForm from '~/mixins/getErrorsForm'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\-\s]*$/)
const containsPasswordRequirement = helpers.regex(
  'The password requires an uppercase, lowercase, number',
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
)
export default {
  name: 'SignUp',
  components: {
    AuthOverlay,
    DropdownSelect,
    PasswordInput,
  },
  mixins: [validationMixin, validationWatcher, getErrorsForm],
  data() {
    return {
      message: '',
      step: 1,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        code: '',
        street: '',
        building: '',
        apartment: '',
        city: '',
        voivodeship: '',
        password: '',
        password_confirmation: '',
        agree: true,
        subscribe: true,
      },
      citys: [],
      voivodeships: [],
      streets: [],
      locationWasNotFound: false,
    }
  },
  validations() {
    const rules = {
      form: {},
    }

    if (this.step === 1) {
      rules.form.first_name = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(32),
        wrongFormat,
      }
      rules.form.last_name = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(32),
        wrongFormat,
      }
      rules.form.email = {
        required,
        email,
        minLength: minLength(5),
        maxLength: maxLength(64),
        emailValidationText,
      }
      rules.form.phone = { required, minLength: minLength(10) }
      rules.form.password = {
        required,
        minLength: minLength(8),
        maxLength: maxLength(36),
        containsPasswordRequirement,
      }
      rules.form.password_confirmation = {
        required,
        errorPasswordConfirmation: sameAs('password'),
      }
    } else {
      rules.form.company_name = {
        minLength: minLength(3),
        maxLength: maxLength(90),
      }
      rules.form.code = { required }
      rules.form.street = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      }
      rules.form.building = { required, maxLength: maxLength(10) }
      rules.form.apartment = { maxLength: maxLength(10) }
      rules.form.city = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      }
      rules.form.voivodeship = {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      }
      rules.form.agree = {
        errorAgree: sameAs(() => true),
      }
    }
    return rules
  },
  computed: {
    attrs() {
      return {
        title: this.$t('registration'),
        width: '430px',
      }
    },
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.message, {
        autoHideDelay: 3000,
        variant,
        solid: true,
      })
    },
    prevStep() {
      this.step = 1
      this.$v.form.$reset()
      this.setValidationSettings(false)
    },
    nextStep() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      this.setValidationSettings(false)
      this.step = 2
    },
    cancel() {
      this.$router.push({ path: 'auth', query: { type: 'login' } })
    },
    async registration() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      this.setValidationSettings(false)

      const form = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone.replace(/[^+0-9]/g, ''),
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        company_name: this.form.company_name,
        address: {
          code: this.form.code,
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          street: this.form.street,
          building: this.form.building,
          apartment: this.form.apartment,
        },
        agree: this.form.agree,
        subscribe: this.form.subscribe,
      }
      for (const propName in form.address) {
        if (form.address[propName] === '' || form.address[propName] === null) {
          delete form.address[propName]
        }
      }

      try {
        await this.$api.auth.register(form)
        this.errorsForm = {}
        await this.$router.push({
          path: 'auth',
          query: { type: 'success', email: this.form.email },
        })
      } catch (error) {
        this.message = ''
        const { response } = error
        this.errorsForm = response.data.errors
        const errors = Object.values(response.data.errors)
        errors.forEach((error) => (this.message += error + '\n'))
        this.makeToast('danger')
      }
    },
    async getCode() {
      try {
        const response = await this.$api.user.getCodeRegistration(
          this.form.code,
        )
        this.locationWasNotFound = response.data.length === 0
        const voivodeships = []
        const citys = []
        const streets = []

        response.data.forEach((item, i) => {
          if (!!item.voivodeship) {
            voivodeships.push({ key: i, type: item.voivodeship })
          }
        })

        response.data.forEach((item, i) => {
          if (!!item.city) {
            citys.push({ key: i, type: item.city })
          }
        })

        response.data.forEach((item, i) => {
          if (!!item.street) {
            streets.push({ key: i, type: item.street })
          }
        })

        const uniq = {}
        this.citys = citys.filter(
          (item) => !uniq[item.type] && (uniq[item.type] = true),
        )
        this.streets = streets.filter(
          (item) => !uniq[item.type] && (uniq[item.type] = true),
        )
        this.voivodeships = voivodeships.filter(
          (item) => !uniq[item.type] && (uniq[item.type] = true),
        )
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
