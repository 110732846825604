//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Benefit1 from '~/assets/images/benefits-1.png'
import Benefit2 from '~/assets/images/benefits-2.png'
import Benefit3 from '~/assets/images/benefits-3.png'
import Benefit4 from '~/assets/images/benefits-4.png'

export default {
  data() {
    return {
      benefits: [
        {
          preview: Benefit1,
          name: this.$t('benefits.item1.name'),
          description: this.$t('benefits.item1.description'),
        },
        {
          preview: Benefit2,
          name: this.$t('benefits.item2.name'),
          description: this.$t('benefits.item1.description'),
        },
        {
          preview: Benefit3,
          name: this.$t('benefits.item3.name'),
          description: this.$t('benefits.item3.description'),
        },
        {
          preview: Benefit4,
          name: this.$t('benefits.item4.name'),
          description: this.$t('benefits.item4.description'),
        },
      ],
    }
  },
}
